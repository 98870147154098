// Modules
import React, { useState, useEffect } from 'react';

// Utils
import Env from 'Env';
import {
  createJwtToken,
  deleteCreditCard,
  getCreditCard,
  paymentSuccess,
} from 'utils/api/payfabricApi';
import { upsertCustomerWithNewCreditCard } from 'utils/api/customerApi';

// Google Analytics
import {
  invoiceDetailsLoadGAEvent,
  invoiceDetailsClickGAEvent,
  paymentMethodButtonsLoadGAEvent,
  makePaymentButtonClickGAEvent,
} from './googleAnalyticsHandlers';

// Components
import {
  InvoiceDetails,
  PaymentMethodButtons,
  MakePaymentButton,
  SpreedlyExpressForm,
  SplititHostedForm,
  PayFabricPaymentsSDK,
  paymentTypes,
} from '@vitusvet/react-library';
import {
  Modal,
  ModalTitle,
  ModalBody,
  ModalFooter,
  Button,
} from 'react-bootstrap';
import ModalHeader from 'react-bootstrap/ModalHeader';
import Loading from 'components/Common/Loading';

// Constants
import Environments from 'constants/Environments';

// CSS
import styles from './PaymentLinkMainContent.module.scss';

function PaymentLinkMainContent(props) {
  const {
    total,
    transaction,
    practice,
    formType,
    display,
    dispatchDisplay,
    paymentType,
    displayTypes,
    paymentButtons,
    handlePaymentButtonClick,
    handleSpreedlyTokenSuccess,
    handleSpreedlyScriptLoadError,
    handleClosePaymentForm,
    handlePayFabricSuccess,
    handlePayFabricError,
    redirectUrls,
    splititInitUrl = '',
    setCardType,
    setLastFourDigits,
    queryParams,
    handlePayBySavedCard,
  } = props;
  const { showForm } = display;

  const [token, setToken] = useState('');
  const [isPayFabricLoading, setIsPayFabricLoading] = useState(false);
  const [showOverwriteSavedCardModal, setShowOverwriteSavedCardModal] =
    useState(false);

  const showMainContent =
    Object.values(display).every((val) => !val) || showForm;
  const transactionId = transaction?.charge?.id;
  const hasPayFabricId = /^pf_/.test(transactionId);
  const hasSpreedlyId = /^sr_/.test(transactionId);

  const handleCancelPayFabricForm = () => {
    handleClosePaymentForm();
    setIsPayFabricLoading(false);
  };

  const handlePayFabricTransactionCompleted = async (msg) => {
    setIsPayFabricLoading(true);
    try {
      await paymentSuccess(transactionId, msg);

      if (transaction?.petData?.owner?.customerId) {
        const { practiceId } = transaction;
        const { customerId, paymentMethodId } = transaction?.petData?.owner;
        const { creditCardsInWallet } = await getCreditCard(
          customerId,
          practiceId,
          false
        );
        const isOldCustomerWithNewCreditCard = !!(
          paymentMethodId && creditCardsInWallet > 1
        );
        const isNewCustomer = !paymentMethodId && creditCardsInWallet;

        if (isOldCustomerWithNewCreditCard) {
          setShowOverwriteSavedCardModal(true);
        } else if (isNewCustomer) {
          await overwriteSavedCard();
        } else {
          handlePayFabricSuccess();
        }
      } else {
        handlePayFabricSuccess();
      }

      setIsPayFabricLoading(false);
    } catch (error) {
      console.log(error);
      setIsPayFabricLoading(false);
      handlePayFabricError(error.message ?? error);
    }
  };

  const handlePayFabricTransactionFailed = async (msg) => {
    setIsPayFabricLoading(true);
    handlePayFabricError(msg.errorMessage);
    setIsPayFabricLoading(false);
  };

  const handleMakePayment = async () => {
    makePaymentButtonClickGAEvent(paymentType);

    paymentType === paymentTypes?.savedCard
      ? await handlePayBySavedCard()
      : dispatchDisplay({ type: displayTypes?.showForm });
  };

  const overwriteSavedCard = async () => {
    const { practiceId } = transaction;
    const {
      phone: petOwnerPhone,
      email: petOwnerEmail,
      customerId,
      paymentMethodId,
    } = transaction?.petData?.owner;

    setIsPayFabricLoading(true);
    await upsertCustomerWithNewCreditCard(
      paymentMethodId && { payment_methods: [{ id: paymentMethodId }] },
      customerId,
      practiceId,
      petOwnerPhone,
      petOwnerEmail,
      { setCardType, setLastFourDigits, queryParams }
    );

    await closeOverwriteModal(false);
  };

  const closeOverwriteModal = async (deleteNewestCreditCard) => {
    const { practiceId } = transaction;
    const { customerId } = transaction?.petData?.owner;

    if (deleteNewestCreditCard) {
      setIsPayFabricLoading(true);
      await deleteCreditCard(customerId, practiceId, false);
      setIsPayFabricLoading(false);
    }

    setShowOverwriteSavedCardModal(false);
    handlePayFabricSuccess();
  };

  useEffect(
    () => {
      if (!hasPayFabricId || paymentType !== paymentTypes.full || !showForm)
        return;

      setIsPayFabricLoading(true);
      getToken();

      async function getToken() {
        const jwtToken = await createJwtToken(transactionId);
        setToken(jwtToken);
      }
    },
    // We need showForm in the dependency array to get a new token
    // if the user clicks the cancel button and then tried to come back
    // Otherwise PayFabric will display an error
    // eslint-disable-next-line
    [hasPayFabricId, transactionId, showForm]
  );

  return (
    <>
      {showMainContent && (
        <InvoiceDetails
          total={total}
          idObj={{ label: 'Invoice #', value: transaction?.invoiceId }}
          lineItems={[
            {
              name: transaction?.charge?.serviceType,
              quantity: '1',
              price: total,
            },
          ]}
          gaEventHandlers={{
            click: invoiceDetailsClickGAEvent,
            load: invoiceDetailsLoadGAEvent,
          }}
          startExpanded={paymentButtons.length > 1 ? false : true}
        />
      )}

      {showMainContent && (
        <PaymentMethodButtons
          clickHandler={handlePaymentButtonClick}
          buttons={paymentButtons}
          selected={paymentType}
          gaEventHandlers={{
            load: () => paymentMethodButtonsLoadGAEvent(paymentType),
          }}
        />
      )}

      {hasSpreedlyId && paymentType === paymentTypes.full && (
        <SpreedlyExpressForm
          total={total}
          fullName={transaction?.petData?.owner?.name}
          practiceName={practice?.name}
          invoiceId={transaction?.invoiceId}
          formType={formType}
          spreedlyEnvironmentKey={Env.spreedlyEnvironmentKey}
          spreedlyTokenSuccessHandler={handleSpreedlyTokenSuccess}
          spreedlyCloseHandler={handleClosePaymentForm}
          spreedlyScriptLoadErrorHandler={handleSpreedlyScriptLoadError}
          showForm={showForm}
        />
      )}

      {hasPayFabricId && paymentType === paymentTypes.full && (
        <Modal
          show={showForm}
          onHide={
            showOverwriteSavedCardModal
              ? async () => closeOverwriteModal(true)
              : handleClosePaymentForm
          }
          size='md'
          aria-labelledby='payment-modal'
          centered
        >
          {showOverwriteSavedCardModal && (
            <ModalHeader>
              <ModalTitle>Overwrite Existing Saved Card</ModalTitle>
            </ModalHeader>
          )}

          <ModalBody>
            {isPayFabricLoading && (
              <div className={styles.payFabricLoading}>
                <Loading />
              </div>
            )}

            {!showOverwriteSavedCardModal && (
              <div className={styles.payFabricHostedForm}>
                {token && (
                  <PayFabricPaymentsSDK
                    token={token}
                    handleFormLoaded={() => setIsPayFabricLoading(false)}
                    handleCancel={handleCancelPayFabricForm}
                    handleTransactionCompleted={
                      handlePayFabricTransactionCompleted
                    }
                    handleTransactionFailed={handlePayFabricTransactionFailed}
                    isProduction={Env.environment === Environments.Production}
                  />
                )}
              </div>
            )}

            {showOverwriteSavedCardModal && (
              <div className={styles.textContainer}>
                You already have a card saved with us, would you like to
                overwrite your existing saved card?
              </div>
            )}
          </ModalBody>
          {showOverwriteSavedCardModal && (
            <ModalFooter>
              <div className={styles.buttonContainer}>
                <Button
                  variant='light'
                  onClick={async () => closeOverwriteModal(true)}
                  disabled={isPayFabricLoading}
                >
                  NO
                </Button>
                <Button
                  variant='secondary'
                  onClick={async () => overwriteSavedCard()}
                  disabled={isPayFabricLoading}
                >
                  YES
                </Button>
              </div>
            </ModalFooter>
          )}
        </Modal>
      )}

      {splititInitUrl && paymentType === paymentTypes.installments && (
        <SplititHostedForm
          showForm={showForm}
          formType={formType}
          transactionId={
            transaction?.transactionId
              ? transaction?.transactionId
              : transaction?.charge?.id
          }
          practiceId={practice?.id}
          practiceName={practice?.name}
          invoiceId={transaction?.invoiceId}
          amount={total}
          splititInitUrl={splititInitUrl}
          redirectUrls={redirectUrls}
          fullName={transaction?.petData?.owner?.name}
          phoneNumber={transaction?.petData?.owner?.phone}
          staffMember={transaction?.charge?.user?.name}
          splititLoadingEndHandler={() => {
            console.log('%csplitit loaded', 'color: limegreen');
          }}
        />
      )}

      {showMainContent && (
        <MakePaymentButton
          clickHandler={async () => handleMakePayment()}
          isDisabled={showForm}
        />
      )}
    </>
  );
}

export default PaymentLinkMainContent;
