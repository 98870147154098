// Modules
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Utils
import { toast } from 'react-toastify';

// Api
import { getCustomers, deleteCustomer } from 'utils/api/customerApi';
import { deleteCreditCard } from 'utils/api/payfabricApi';

// Components
import CustomersHeader from './CustomersHeader';
import CustomersList from './CustomersList';
import DeleteCustomerModal from './DeleteCustomerModal';
import {
  EditCardModal,
  EditCustomerModal,
  NewCustomerModal,
} from './CustomerModal/CustomerModal';

// Constants
import PaymentPlatforms from 'constants/PaymentPlatforms';

const defaultSizePerPage = 10;

const modalOptions = Object.freeze({
  NONE: 0,
  NEW_CUSTOMER: 1,
  EDIT_CUSTOMER: 2,
  DELETE_CUSTOMER: 3,
  EDIT_CARD: 4,
});

export function Customers() {
  const [isLoading, setLoading] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCustomers, setTotalCustomers] = useState(0);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [modalState, setModalState] = useState(modalOptions.NONE);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);

  // we can use this trigger to force the component to maintain
  // its state and refetch the data for the current page
  const [refetchTrigger, setRefetchTrigger] = useState(false);

  const { id: practiceId, paymentPlatform } = useSelector(
    (s) => s.loggedPractice
  );

  useEffect(() => {
    let didCancel = false;

    setRefetchTrigger(false);
    const fetchCustomers = async () => {
      let params = {
        size: defaultSizePerPage,
        page: currentPage - 1,
      };
      if (searchValue) params.search = searchValue;

      try {
        setLoading(true);
        setCustomersList([]);

        const result = await getCustomers(params);

        if (!didCancel) {
          setTotalCustomers(result.data.total);
          setCustomersList(result.data.rows);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        toast.error('Unable to get customers');
      }
    };

    fetchCustomers();

    return () => {
      didCancel = true;
    };
  }, [currentPage, searchValue, refetchTrigger]);

  // pagination control
  const handleTableChange = (type, { page }) => {
    setCurrentPage(page);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setCurrentPage(1);
  };

  const handleShowDelete = (customer) => {
    setSelectedCustomer(customer);
    setModalState(modalOptions.DELETE_CUSTOMER);
  };

  const handleShowEdit = (customer) => {
    setSelectedCustomer(customer);
    setModalState(modalOptions.EDIT_CUSTOMER);
  };

  const handleShowEditCard = (customer) => {
    setSelectedCustomer(customer);
    setModalState(modalOptions.EDIT_CARD);
  };

  const handleAddCustomer = (customer) => {
    if (currentPage === 1) {
      setCustomersList((cl) => [customer, ...cl.slice(0, 9)]);
    }
  };

  const handleUpdateCustomer = (updateCustomer) => {
    const updatedCustomerList = customersList.map((customer) => {
      if (customer.customer_id === updateCustomer.customer_id) {
        return { ...customer, ...updateCustomer };
      }

      return customer;
    });

    setCustomersList(updatedCustomerList);
  };

  const handleDeleteCustomer = async (customerId) => {
    try {
      setDeleteModalLoading(true);

      if (paymentPlatform === PaymentPlatforms.payfabric) {
        await deleteCreditCard(customerId, practiceId);
      }

      await deleteCustomer(customerId);

      // if there are no more customers on the page after deleting
      // we need to go back a page
      if (customersList.length <= 1) {
        setCurrentPage((page) => page - 1);
      } else {
        // if there are still customers on the current page, trigger
        // a refetch of the current page
        setRefetchTrigger(true);
      }
      setModalState(modalOptions.NONE);
      setDeleteModalLoading(false);
    } catch (err) {
      console.error(err);
      toast.error('Unable to delete customer');
    }
  };

  return (
    <div id='Customers' className='content'>
      <CustomersHeader title={'Customers'} onSearch={handleSearch} />
      <CustomersList
        customers={customersList}
        onRefresh={getCustomers}
        page={currentPage}
        sizePerPage={defaultSizePerPage}
        totalSize={totalCustomers}
        onTableChange={handleTableChange}
        isLoading={isLoading}
        buttonFuncs={{
          onDelete: handleShowDelete,
          onEdit: handleShowEdit,
          onEditCard: handleShowEditCard,
        }}
        onNewCustomerModalShow={() => setModalState(modalOptions.NEW_CUSTOMER)}
      />
      <DeleteCustomerModal
        customer={selectedCustomer}
        isLoading={deleteModalLoading}
        show={modalState === modalOptions.DELETE_CUSTOMER}
        onDelete={handleDeleteCustomer}
        onCancel={() => setModalState(modalOptions.NONE)}
        onExited={() => setModalState(modalOptions.NONE)}
      />
      <NewCustomerModal
        show={modalState === modalOptions.NEW_CUSTOMER}
        onHide={() => setModalState(modalOptions.NONE)}
        onAdd={handleAddCustomer}
        practiceId={practiceId}
      />
      <EditCustomerModal
        show={modalState === modalOptions.EDIT_CUSTOMER}
        onHide={() => setModalState(modalOptions.NONE)}
        customerToEdit={selectedCustomer}
        onUpdate={handleUpdateCustomer}
        practiceId={practiceId}
      />
      <EditCardModal
        show={modalState === modalOptions.EDIT_CARD}
        onHide={() => setModalState(modalOptions.NONE)}
        customerToEdit={selectedCustomer}
        onUpdate={handleUpdateCustomer}
        practiceId={practiceId}
      />
    </div>
  );
}

export default Customers;
