import {
  axiosPayFabricInstance,
  axiosPayFabricPublicInstance,
  handleResponse,
  handleError,
} from './index';

export async function getSetupIds(deviceId, devicePassword) {
  try {
    const response = await axiosPayFabricInstance.post('/setupid', {
      deviceId,
      devicePassword,
    });

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

/**
 * Process a payfabric Transaction call
 * @param {string} transactionId
 * @param {string} refundAmount
 * @param {string} refundReason
 * @returns
 */
export async function processPayfabricRefund(
  transactId,
  refundAmount,
  refundReason
) {
  try {
    const body = {
      transactId,
      refundAmount: parseFloat(refundAmount),
      customRefundReason: refundReason,
    };
    const response = await axiosPayFabricInstance.post('/payment/refund', body);

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function createDraftTransaction(reqBody) {
  try {
    const response = await axiosPayFabricInstance.post('/draft', reqBody);

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function practiceProcessTransaction(reqBody) {
  try {
    const response = await axiosPayFabricInstance.post(
      '/transactions/practices',
      reqBody
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function customerProcessTransaction({
  transactionId,
  customerId,
  creditCardId,
}) {
  try {
    const response = await axiosPayFabricPublicInstance.post(
      '/transactions/customers',
      {
        transactionId,
        customerId,
        creditCardId,
      }
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function createPayFabricFormUrl({
  transactionId,
  isTerminalEntry = false,
}) {
  try {
    const response = await axiosPayFabricPublicInstance.post('/url', {
      transactionId,
      isTerminalEntry,
    });

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function createJwtToken(transactionId) {
  try {
    const response = await axiosPayFabricPublicInstance.post('/token', {
      transactionId,
    });

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function paymentSuccess(
  transactionId,
  payfabricEvent,
  isTerminalEntry = false
) {
  try {
    const response = await axiosPayFabricPublicInstance.post(
      '/payment/success',
      {
        transactionId,
        payfabricEvent,
        isTerminalEntry,
      }
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function cancelPayFabricTransaction(transactionId) {
  try {
    const response = await axiosPayFabricInstance.post('/cancel', {
      transactionId,
    });

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function voidPayFabricTransaction(transactionId, voidReason) {
  try {
    const response = await axiosPayFabricInstance.post('/void', {
      transactionId,
      voidReason,
    });

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function createPaymentLink(reqBody) {
  try {
    const response = await axiosPayFabricInstance.post(
      '/payment-link',
      reqBody
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function resendPayFabricPaymentLink(reqBody) {
  try {
    const response = await axiosPayFabricInstance.post(
      '/payment-link/resend',
      reqBody
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function createWalletFormUrl(customerId, practiceId) {
  try {
    const response = await axiosPayFabricPublicInstance.post('/url/wallet', {
      customerId,
      practiceId,
    });

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function getCreditCard(customerId, practiceId, oldest = true) {
  try {
    const response = await axiosPayFabricPublicInstance.get(
      `/credit-card/${customerId}/${practiceId}/${oldest ? '1' : '0'}`
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}

export async function deleteCreditCard(customerId, practiceId, oldest = true) {
  try {
    const response = await axiosPayFabricPublicInstance.delete(
      `/credit-card/${customerId}/${practiceId}/${oldest ? '1' : '0'}`
    );

    return handleResponse(response);
  } catch (err) {
    return handleError(err);
  }
}
