// Modules
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { savePractice } from 'utils/store/actions/practicesActions';
import { toast } from 'react-toastify';
import {
  getRegisteredFieldsJSX,
  getBasicDetailsJSX,
  validateFormData,
  showValidationStyles,
  getUpdatedFormData,
} from './practiceEditViewFunctions';

// Utils
import { produce } from 'immer';
import { serializeForm } from 'utils/helpers/formDataHelpers';

// Styles
import styles from './PracticeEditView.module.scss';

// Components
import { Button } from 'react-bootstrap';
import Loading from 'components/Common/Loading';
import CustomMessageModal from './CustomMessageModal';
import ModalFormElements from './ModalFormElements';
import PaymentPlatforms from 'constants/PaymentPlatforms';
import { DeviceTypes } from 'constants/Payfabric';

function practiceHasPaymentLinkConfig(practice) {
  return hasValidSpreedlyConfig(practice) || hasValidPayFabricConfig(practice);
}

function hasValidSpreedlyConfig(practice) {
  return !!(
    practice?.spreedlyGateway?.authNet?.login &&
    practice?.spreedlyGateway?.authNet?.token
  );
}

function hasValidPayFabricConfig(practice) {
  return !!(
    practice?.payFabricDeviceIds?.defaultDeviceId &&
    practice?.payFabricSetupIds?.defaultSetupId
  );
}

/**
 * ==================
 * Exported component
 * ==================
 */
function PracticeEditView(props) {
  const {
    practice,
    toggleEditMode,
    updateSuccess,
    basicDetailsWithVals,
    paymentOptionsRegisteredFields,
    paxDeviceRegisteredFields,
    updatePracticeFields,
  } = props;

  const [updating, setUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [customMessageText, setCustomMessageText] = useState(
    practice?.paymentLinkCustomMessage ?? ''
  );
  const [connectTo, setConnectTo] = useState('');
  const [payProcessorUpdateData, setPayProcessorUpdateData] = useState({});
  const [currentPayFabricDeviceIds, setCurrentPayFabricDeviceIds] = useState(
    practice?.payFabricDeviceIds
  );

  const dispatch = useDispatch();

  const handlePayProcessorJSX = () => {
    const payProcessorFields = [
      'stripeId',
      'authNetLogin',
      'authNetPassword',
      'payFabricDeviceId',
      'payFabricSetupId',
    ];

    const JSX = payProcessorFields.map((fieldName) => {
      const copy = { ...paymentOptionsRegisteredFields[fieldName] };
      copy.val = payProcessorUpdateData[fieldName]
        ? 'Pending save...'
        : copy.val;
      copy.mapToJSX = true;
      return getRegisteredFieldsJSX(copy);
    });

    return JSX;
  };

  const handlePaymentLink = () => {
    const copy = { ...paymentOptionsRegisteredFields.paymentLinkEnabled };

    copy.isDisabled = !practiceHasPaymentLinkConfig(practice);

    copy.mapToJSX = true;

    return getRegisteredFieldsJSX(copy);
  };

  const handleOnlyVetshipRxPayments = () => {
    const copy = { ...paymentOptionsRegisteredFields.onlyVetshipRxPayments };

    copy.isDisabled = paymentOptionsRegisteredFields.paymentLinkEnabled.val;

    copy.mapToJSX = true;

    return getRegisteredFieldsJSX(copy);
  };

  const handleCustomMessage = () => {
    const copy = { ...paymentOptionsRegisteredFields.paymentLinkCustomMessage };

    copy.isDisabled = !practiceHasPaymentLinkConfig(practice);

    copy.mapToJSX = true;

    copy.handleClick = () => {
      setShowMessageModal(true);
    };

    return getRegisteredFieldsJSX(copy);
  };

  const handleConnectToPayFabric = () => {
    setShowModal(true);
    setConnectTo(PaymentPlatforms.payfabric);
  };

  const handleConnectToSpreedly = () => {
    setShowModal(true);
    setConnectTo(PaymentPlatforms.spreedly);
  };

  const handleConnectToStripe = () => {
    setShowModal(true);
    setConnectTo(PaymentPlatforms.stripe);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = e.target;

    const formData = serializeForm(form);

    const validationObj = validateFormData(formData);

    const formIsValid = Object.values(validationObj).every((val) => val);

    showValidationStyles(form, validationObj, styles);

    if (!formIsValid) return;
    const updatedFormData = getUpdatedFormData(
      formData,
      payProcessorUpdateData
    );

    updatedFormData.paymentLinkCustomMessage = customMessageText;

    if (practice && practice.payFabricDeviceIds) {
      practice.payFabricDeviceIds = {
        ...currentPayFabricDeviceIds,
      };
    }
    const practiceCopy = produce(practice, (draft) => {
      return {
        ...draft,
        ...updatedFormData,
      };
    });

    updatePracticeFields(practiceCopy);

    try {
      setUpdating(true);

      await savePractice(practiceCopy)(dispatch);

      updateSuccess();

      toast.success('Practice info has been saved');
    } catch (err) {
      setUpdating(false);

      console.log(err);

      toast.error(err.userMessage ? err.userMessage : err.message); //'Error saving practice info'
    }
  };

  //#region PayFabric Related Functions
  const deletePayfabricDeviceId = (device) => {
    const copyOfDeviceIds = { ...currentPayFabricDeviceIds };
    copyOfDeviceIds[device[0]] = '';
    setCurrentPayFabricDeviceIds(copyOfDeviceIds);
  };

  const resetPayFabricDeviceIds = () => {
    setCurrentPayFabricDeviceIds(practice?.payFabricDeviceIds);
  };

  const addPayFabricDeviceId = (deviceId, deviceType) => {
    const copyOfDeviceIds = { ...currentPayFabricDeviceIds };
    const { id } = DeviceTypes[deviceType];
    copyOfDeviceIds[id] = deviceId;
    setCurrentPayFabricDeviceIds(copyOfDeviceIds);
  };
  //#endregion

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.basicDetailsGrid}>
        {basicDetailsWithVals.map((detail) => getBasicDetailsJSX(detail))}
      </div>

      {/* Payment Options Section */}
      <hr className={styles.hr} />
      <p className='mb-4' style={{ fontSize: '14px' }}>
        Payment Options
      </p>
      <div className={styles.paymentOptionsGridContainer}>
        {handlePayProcessorJSX()}

        {handlePaymentLink()}

        {handleOnlyVetshipRxPayments()}

        {handleCustomMessage()}

        {Object.entries(paymentOptionsRegisteredFields).map(([name, data]) =>
          getRegisteredFieldsJSX(data)
        )}
      </div>

      {/* Pax Device Settings Section */}
      <hr className={styles.hr} />
      <p className='mb-4' style={{ fontSize: '14px' }}>
        Pax Device Settings
      </p>
      <div className={styles.paxDeviceGridContainer}>
        {Object.entries(paxDeviceRegisteredFields).map(([name, data]) =>
          getRegisteredFieldsJSX(data)
        )}
      </div>

      <CustomMessageModal
        showModal={showMessageModal}
        setShowModal={setShowMessageModal}
        currentMessage={practice?.paymentLinkCustomMessage ?? ''}
        customMessageText={customMessageText}
        setCustomMessageText={setCustomMessageText}
      />

      <ModalFormElements
        accountName={practice.name}
        addPayFabricDeviceId={addPayFabricDeviceId}
        connectTo={connectTo}
        currentPayFabricDeviceIds={currentPayFabricDeviceIds}
        deletePayfabricDeviceId={deletePayfabricDeviceId}
        payProcessorUpdateData={payProcessorUpdateData}
        resetPayFabricDeviceIds={resetPayFabricDeviceIds}
        setPayProcessorUpdateData={setPayProcessorUpdateData}
        setShowModal={setShowModal}
        showModal={showModal}
      />

      <div className={styles.buttonContainerCol}>
        <Button variant='outline-secondary' onClick={handleConnectToPayFabric}>
          CONNECT TO PAYFABRIC
        </Button>
        <Button variant='outline-secondary' onClick={handleConnectToSpreedly}>
          CONNECT TO SPREEDLY
        </Button>
        <Button variant='outline-secondary' onClick={handleConnectToStripe}>
          CONNECT TO STRIPE
        </Button>
      </div>

      <hr className={styles.hr} />

      <div className={styles.buttonContainer}>
        {updating ? (
          <Loading
            style={{
              alignItems: 'center',
            }}
            message='Updating practice...'
          />
        ) : (
          <>
            <Button variant='light' className='mr-2' onClick={toggleEditMode}>
              CANCEL
            </Button>
            <Button type='submit'>SAVE</Button>
          </>
        )}
      </div>
    </form>
  );
}

export default PracticeEditView;
