// Modules
import React, { useState, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';

// API
import { postSpreedlyPaymentInfo } from 'utils/store/actions/transactionsActions';
import {
  postTransactionReceipt,
  getPaymentLinkData,
  getTransactionPaymentMethod,
} from 'utils/api/transactionPublicApi';
import { getPrivacyPolicy } from 'utils/api/servicesApi';
import { customerProcessTransaction } from 'utils/api/payfabricApi';
import TransactionStatus from 'constants/TransactionStatus';

// Google Analytics
import {
  spreedlyCloseGAEvent,
  receiptSubmitGAEvent,
  orderCompleteLoadGAEventHandler,
  paymentMethodButtonsClickGAEvent,
  splititCanceledGAEventHandler,
  paymentSuccessShowReceiptOptionsGAEvent,
} from './googleAnalyticsHandlers';

// Helpers
import {
  getDecimalTotalStr,
  getCardErrorMessage,
  getFormType,
  displayTypes,
  displayInitState,
  displayReducer,
  getGALabel,
} from './paymentLinkHelpers';
import Env from 'Env';

// Styles
import styles from './PaymentLink.module.scss';

// Components
import {
  receiptTypes,
  paymentTypes,
  getPaymentButtonObjects,
  Layout,
  PracticeBanner,
  FlavorText,
  PoweredByVitusPay,
  OrderComplete,
  ReceiptButtons,
  SuccessHeader,
  getRedirectUrls,
} from '@vitusvet/react-library';
import Loading from 'components/Common/Loading';
import PaymentLinkErrors from './PaymentLinkErrors';
import PaymentLinkMainContent from './PaymentLinkMainContent';
import PrivacyPolicyModal from './PrivacyPolicyModal';
import { insertInstallmentPlanIntoTransaction } from 'utils/api/installmentPublicApi';
import PaymentMethod from 'constants/PaymentMethod';

async function addInstallmentPlanToDB(args) {
  const {
    reqBody,
    dispatchDisplay,
    setErrorMessage,
    setTransaction,
    setPractice,
  } = args;

  dispatchDisplay({ type: displayTypes?.loading });

  try {
    const res = await insertInstallmentPlanIntoTransaction(reqBody);

    const { transaction = {}, practice = {} } = res;

    if (!('transactionId' in transaction) || !('id' in practice))
      throw new Error(
        `Could not get correct data from server
        \ntransaction: ${JSON.stringify(transaction)}
        \npractice: ${JSON.stringify(practice)}`
      );

    setTransaction(transaction);

    setPractice(practice);

    dispatchDisplay({ type: displayTypes?.finished });
  } catch (err) {
    console.log(err);

    setErrorMessage(
      `Transaction number: ${reqBody.transactionId}\nInstallment plan number: ${reqBody.installmentPlanNumber}`
    );

    dispatchDisplay({ type: displayTypes?.showDBInsertFailed });
  }
}

function setInitialTransactionScreen(dispatchDisplay, transaction, statusCode) {
  switch (transaction?.status) {
    case TransactionStatus.succeeded:
      dispatchDisplay({ type: displayTypes?.alreadyCompleted });
      break;

    case TransactionStatus.expired:
      dispatchDisplay({ type: displayTypes?.transactionExpired });
      break;

    default:
      statusCode === 206
        ? dispatchDisplay({ type: displayTypes?.partialData })
        : dispatchDisplay({ type: displayTypes?.finishLoading });
      break;
  }
}

async function getTransactionData(args) {
  const {
    queryParams,
    dispatchDisplay,
    setTotal,
    setPaymentButtons,
    setPaymentType,
    setTransaction,
    setPractice,
    setRedirectUrls,
    setSplititInitUrl,
    setPaymentMethod,
  } = args;

  try {
    /**
     * Contains both transaction and practice data
     * Hence you only need one HTTP request
     */
    const response = await getPaymentLinkData(queryParams);

    const data = response?.data ?? response;

    if (!data) throw new Error('Something went wrong getting the data');

    const { transaction, practice } = data;

    const statusCode = response?.status ?? response?.statusCode ?? 400;

    // If server responds with 200 we have the full data
    // If server responds with 206 we have partial data
    // For example, a transaction that was already completed would return partial data
    if (statusCode !== 200 && statusCode !== 206)
      throw new Error('Something went wrong getting the transaction');

    if (statusCode === 200) {
      const totalStr = getDecimalTotalStr(transaction?.charge?.amount);
      const showInstallments = transaction?.includePaymentPlanOption ?? false;
      const paymentMethodId = transaction?.petData?.owner?.paymentMethodId;
      const paymentMethod = paymentMethodId
        ? await getTransactionPaymentMethod(queryParams, paymentMethodId)
        : null;

      if (paymentMethodId) {
        setPaymentMethod(paymentMethod);
        setPaymentType(paymentTypes?.savedCard);
      }

      const paymentButtons = getPaymentButtonObjects(
        totalStr,
        showInstallments,
        paymentMethod
      );

      if (showInstallments) {
        // Make sure to include the starting "/" in the path
        setRedirectUrls(getRedirectUrls('/pay', queryParams));
        setSplititInitUrl(`${Env.endpoints.installments}/initSplitit`);
        setPaymentType(paymentTypes?.installments);
      }

      setTotal(totalStr);
      setPaymentButtons(paymentButtons);
    }

    setTransaction(transaction);
    setPractice(practice);
    setInitialTransactionScreen(dispatchDisplay, transaction, statusCode);
  } catch (err) {
    console.log(err);
    dispatchDisplay({ type: displayTypes?.showDataFailed });
  }
}

/**
 * ==================
 * Exported component
 * ==================
 */
function PaymentLink(props) {
  const { location } = props;

  const [loadingMessage, setLoadingMessage] = useState('Loading transaction');
  const [errorMessage, setErrorMessage] = useState(getCardErrorMessage());
  const [paymentButtons, setPaymentButtons] = useState([]);
  const [paymentType, setPaymentType] = useState(paymentTypes?.full);
  const [formType, setFormType] = useState('');
  const [total, setTotal] = useState('');
  const [receiptInfo, setReceiptInfo] = useState({});
  const [receiptSentTo, setReceiptSentTo] = useState('');
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState('');
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [cardType, setCardType] = useState('');
  const [lastFourDigits, setLastFourDigits] = useState('');
  const [display, dispatchDisplay] = useReducer(
    displayReducer,
    displayInitState
  );
  const [queryParams, setQueryParams] = useState('');
  const [redirectUrls, setRedirectUrls] = useState({});
  const [splititInitUrl, setSplititInitUrl] = useState('');
  const [transaction, setTransaction] = useState({});
  const [transactionId, setTransactionId] = useState('');
  const [practice, setPractice] = useState({});

  const dispatch = useDispatch();

  const showBanner =
    Object.values(display).every((val) => !val) ||
    display?.showForm ||
    display?.showReceiptButtons ||
    display?.finished ||
    display?.alreadyCompleted ||
    display?.partialData ||
    display?.transactionExpired;

  const handlePaymentButtonClick = (buttonType) => {
    paymentMethodButtonsClickGAEvent(buttonType);
    setPaymentType(buttonType);
  };

  const handlePayBySavedCard = async () => {
    try {
      setLoadingMessage('Processing payment');
      dispatchDisplay({ type: displayTypes?.loading });

      await customerProcessTransaction({
        transactionId,
        customerId: transaction.petData.owner.customerId,
        creditCardId: paymentMethod.payment_method_token,
      });
      handlePayFabricSuccess();
    } catch (e) {
      console.log(e);
      dispatchDisplay({ type: displayTypes?.showError });
      setErrorMessage('There was an issue with PayFabric.');
    }
  };

  const handlePayFabricSuccess = () => {
    dispatchDisplay({ type: displayTypes?.showReceiptButtons });
  };

  const handlePayFabricError = (payFabricErrorMessage) => {
    dispatchDisplay({ type: displayTypes?.showError });
    setErrorMessage(payFabricErrorMessage);
  };

  const handleSpreedlyTokenSuccess = (token) => {
    dispatchDisplay({ type: displayTypes?.tokenSuccess });
    setLoadingMessage('Processing payment');
    postTransaction();

    async function postTransaction() {
      try {
        await postSpreedlyPaymentInfo(transaction?.charge?.id, token)(dispatch);

        dispatchDisplay({ type: displayTypes?.showReceiptButtons });
      } catch (err) {
        const msg = err?.error?.toLowerCase() ?? '';

        const noPeriod =
          msg[msg.length - 1] === '.' ? msg.substring(0, msg.length - 1) : msg;

        const transactionWasCanceled =
          noPeriod === 'this transaction has been canceled';

        if (transactionWasCanceled) {
          return dispatchDisplay({ type: displayTypes?.transactionCanceled });
        }

        setErrorMessage(getCardErrorMessage(noPeriod));

        dispatchDisplay({ type: displayTypes?.showError });
      }
    }
  };

  const handleSpreedlyScriptLoadError = (err) => {
    console.log(err);
    setErrorMessage(
      "Sorry we can't load your payment processor right now, please select try again or contact your practice for assistance"
    );
    dispatchDisplay({ type: displayTypes?.showError });
  };

  const handleClosePaymentForm = () => {
    spreedlyCloseGAEvent();
    dispatchDisplay({ type: displayTypes?.hideForm });
  };

  const handleReceiptSubmit = (receiptObj) => {
    setLoadingMessage(
      receiptObj?.type === receiptTypes?.none ? 'Processing' : 'Sending receipt'
    );
    dispatchDisplay({ type: displayTypes?.sendingReceipt });

    receiptSubmitGAEvent(getGALabel(receiptObj.type));

    async function sendReceipt() {
      try {
        setReceiptSentTo(receiptObj.value);

        await postTransactionReceipt(
          transaction?.charge?.id,
          receiptObj.type,
          receiptObj.value
        );

        setReceiptInfo(receiptObj);

        dispatchDisplay({ type: displayTypes?.finished });
      } catch (err) {
        console.log(err);

        setErrorMessage(`Receipt could not be sent to: ${receiptObj.value}`);

        dispatchDisplay({ type: displayTypes?.receiptFailed });
      }
    }

    sendReceipt();
  };

  const handleReceiptRetry = () => {
    dispatchDisplay({ type: displayTypes?.showReceiptButtons });
  };

  const handleReceiptCancel = () => {
    async function noReceipt() {
      try {
        await postTransactionReceipt(
          transaction?.charge?.id,
          receiptTypes.none,
          ''
        );
      } catch (err) {
        console.log(err);
      } finally {
        dispatchDisplay({ type: displayTypes?.finished });
      }
    }

    noReceipt();
  };

  useEffect(() => {
    // update which form to use any time the paymentType changes
    setFormType(getFormType(paymentType));
  }, [paymentType]);

  useEffect(() => {
    const urlPath = location?.pathname;
    const parsed = queryString.parse(location.search);
    /**
     * This is necessary because the search params in the URL could contain info
     * from Splitit redirects that we don't want to propagate
     */
    const queryParams = `?transactionId=${parsed.transactionId}&key=${parsed.key}`;
    setQueryParams(queryParams);
    setTransactionId(parsed.transactionId);

    const initPrivacyPolicy = async () => {
      const url = await getPrivacyPolicy();
      setPrivacyPolicyUrl(url);
    };

    initPrivacyPolicy();

    const transactionDataArgs = {
      location,
      queryParams,
      dispatchDisplay,
      setTotal,
      setPaymentButtons,
      setPaymentType,
      setTransaction,
      setPractice,
      setRedirectUrls,
      setSplititInitUrl,
      setPaymentMethod,
    };

    /**
     * Because splitit requires 3 redirect urls we must handle those cases
     * along with the default and a 404 for when the path doesn't match any
     */
    switch (urlPath) {
      case '/pay':
        getTransactionData(transactionDataArgs);
        break;

      case '/pay/succeeded':
        const args = {
          dispatchDisplay,
          setErrorMessage,
          setTransaction,
          setPractice,
          reqBody: {
            transactionId: parsed?.transactionId,
            installmentPlanNumber: parsed?.InstallmentPlanNumber,
          },
        };

        addInstallmentPlanToDB(args);
        break;

      case '/pay/canceled':
        splititCanceledGAEventHandler();
        getTransactionData(transactionDataArgs);
        break;

      case '/pay/failed':
        setErrorMessage('Splitit could not process your transaction');
        dispatchDisplay({ type: displayTypes?.splititFailed });
        break;

      default:
        dispatchDisplay({ type: displayTypes?.show404Error });
        break;
    }
  }, [location]);

  return (
    <div className={styles.container}>
      <Layout>
        {display?.loading ? <Loading message={loadingMessage} /> : null}

        {showBanner && (
          <PracticeBanner
            imgUrl={
              practice?.coreId
                ? `${Env.coreUrl}/images/clients/${practice.coreId}/logo.png`
                : ''
            }
            date={
              transaction?.charge
                ? `${new Date(transaction?.charge?.created).toLocaleString(
                    'default',
                    {
                      month: 'short',
                    }
                  )} ${transaction?.charge?.day}, ${transaction?.charge?.year}`
                : ''
            }
            name={practice?.name}
            street={
              practice?.coreData?.address1
                ? `${practice?.coreData?.address1}${
                    practice?.coreData?.address2
                      ? `, ${practice?.coreData?.address2}`
                      : ''
                  }`
                : ''
            }
            city={practice?.coreData?.city ?? ''}
            state={practice?.coreData?.state ?? ''}
            zip={practice?.coreData?.zip ?? ''}
            phone={practice?.phone ? practice?.phone.toString() : ''}
          />
        )}

        <PaymentLinkMainContent
          {...{
            total,
            transaction,
            practice,
            formType,
            display,
            dispatchDisplay,
            paymentType,
            displayTypes,
            paymentButtons,
            handlePaymentButtonClick,
            handleSpreedlyTokenSuccess,
            handleClosePaymentForm,
            handleSpreedlyScriptLoadError,
            handlePayFabricSuccess,
            handlePayFabricError,
            redirectUrls,
            splititInitUrl,
            setCardType,
            setLastFourDigits,
            queryParams,
            handlePayBySavedCard,
          }}
        />

        {display?.showReceiptButtons ? (
          <>
            {paymentSuccessShowReceiptOptionsGAEvent()}
            <SuccessHeader total={total} />
            <ReceiptButtons
              callback={handleReceiptSubmit}
              phone={transaction?.petData?.owner?.phone.replace?.('+1', '')}
              email={transaction?.petData?.owner?.contactEmail}
              cardType={cardType}
              lastFourDigits={lastFourDigits}
              defaultSelected={
                transaction?.charge?.method === PaymentMethod.EmailLink
                  ? receiptTypes.email
                  : receiptTypes.textMessage
              }
            />
          </>
        ) : null}

        {display?.finished && receiptInfo?.type && receiptInfo?.value ? (
          <OrderComplete
            receiptType={receiptInfo?.type}
            sentTo={receiptInfo?.value}
            gaEventHandlers={{
              load: () => orderCompleteLoadGAEventHandler(total),
            }}
          />
        ) : display?.finished ? (
          <OrderComplete />
        ) : null}

        <PaymentLinkErrors
          {...{
            display,
            dispatchDisplay,
            displayTypes,
            errorMessage,
            total,
            practice,
            handleReceiptCancel,
            handleReceiptRetry,
            receiptSentTo,
          }}
        />

        {showBanner && practice?.paymentLinkCustomMessage ? (
          <FlavorText text={`"${practice?.paymentLinkCustomMessage}"`} />
        ) : null}
      </Layout>

      <PoweredByVitusPay />

      <footer className={styles.footer}>
        <button
          className={`btn btn-link ${styles.footerBtn}`}
          onClick={() => setShowPrivacyPolicy((prev) => !prev)}
        >
          Privacy Policy
        </button>

        <small className={styles.copyright}>
          COPYRIGHT {new Date(Date.now()).getFullYear()} &#169; VitusVet All
          rights reserved
        </small>
      </footer>

      <PrivacyPolicyModal
        show={showPrivacyPolicy}
        onClose={() => setShowPrivacyPolicy((prev) => !prev)}
        link={privacyPolicyUrl}
      />
    </div>
  );
}

export default PaymentLink;
