import {
  processRefund,
  processVoid,
  readPracticeTransaction,
  sendTransactionReceipt as sendReceipt,
  createTransaction,
} from './../../api/transactionApi';
import * as spreedlyApi from 'utils/api/spreedlyApi';
import { StoreActions } from '.';
import moment from 'moment';
import {
  cancelPayFabricTransaction,
  voidPayFabricTransaction,
  resendPayFabricPaymentLink,
  processPayfabricRefund,
} from 'utils/api/payfabricApi';
import TransactionSource from 'constants/TransactionSource';

/**
 * PRIVATE: get transaction detail on successful request
 * @param {object} transaction
 */
function getTransactionDetailSuccess(transaction) {
  return {
    type: StoreActions.GET_TRANSACTION_DETAIL_SUCCESS,
    transaction,
  };
}

/**
 * PRIVATE: process transaction refund on successful request
 * @param {object} transaction
 */
function processTransactionRefundSuccess(transaction) {
  return {
    type: StoreActions.PROCESS_TRANSACTION_REFUND_SUCCESS,
    transaction,
  };
}

/**
 * PRIVATE: send transaction receipt on successful request
 * @param {object} transaction
 */
function sendTransactionReceiptSuccess(transaction) {
  return {
    type: StoreActions.SEND_TRANSACTION_RECEIPT_SUCCESS,
    transaction,
  };
}

/**
 * Get transaction detail by id
 * @param {number} id
 */
export function readTransaction(id) {
  return function (dispatch) {
    return readPracticeTransaction(id)
      .then((transaction) => {
        if (transaction.isInstallments) {
          transaction.splitit = transaction.installments;
        }
        dispatch(getTransactionDetailSuccess(transaction));
      })
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * process transaction refund request
 * @param {object} transaction
 */
export function processTransactionRefund(transaction, refundValue) {
  return function (dispatch) {
    const onTransactionSuccess = () => {
      readPracticeTransaction(transaction.transactionId, null, true)
        .then((updatedTransaction) => {
          dispatch(processTransactionRefundSuccess(updatedTransaction));
          setTimeout(() => {
            window.location.reload();
          }, 500);
          return;
        })
        .catch((err) => {
          window.location.reload();
          throw err;
        });
    };

    // TODO: Text2Pay refunds
    if (transaction.isInstallments) {
      //TODO: clean up or figure out how we'll support installments refunds
      throw new Error(
        'Refunds not supported for monthly payment transactions.'
      );
    } else {
      if (transaction.source === TransactionSource.PayFabric) {
        return processPayfabricRefund(
          transaction.transactionId,
          refundValue,
          transaction.custom_refund_reason
        )
          .then(onTransactionSuccess)
          .catch((err) => {
            throw err;
          });
      }

      return processRefund(
        transaction.transactionId,
        refundValue,
        transaction.custom_refund_reason
      )
        .then(onTransactionSuccess)
        .catch((err) => {
          throw err;
        });
    }
  };
}

/**
 * process transaction refund request
 * @param {object} transaction
 */
export function processTransactionVoid(transaction) {
  return function (dispatch) {
    const onTransactionSuccess = () => {
      readPracticeTransaction(transaction.transactionId, null, true)
        .then((updatedTransaction) => {
          dispatch(processTransactionRefundSuccess(updatedTransaction));
        })
        .catch((err) => {
          throw err;
        });
    };

    if (transaction.source === TransactionSource.PayFabric) {
      return voidPayFabricTransaction(
        transaction.transactionId,
        transaction.custom_void_reason
      ).then(onTransactionSuccess);
    }

    return processVoid(
      transaction.transactionId,
      transaction.custom_void_reason
    )
      .then(onTransactionSuccess)
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * send transaction receipt request
 * @param {object} transaction
 * @param {object} loggedPractice
 */
export function sendTransactionReceipt(transaction, loggedPractice) {
  const emailInfo = {
    transaction_id: transaction.transactionId,
    pet_parent_email: transaction.pet_parent_email,
    practice_name: transaction.practice_name,
    date: moment(transaction.time).format('L'),
    amount: transaction.amount / 100,
    card: '*' + transaction.pet_parent_card_digits,
    practice_email: loggedPractice.email,
    practice_phone: loggedPractice.phone,
  };

  return function (dispatch) {
    return sendReceipt(emailInfo)
      .then(() => {
        readPracticeTransaction(transaction.id, transaction, true)
          .then((updatedTransaction) => {
            dispatch(sendTransactionReceiptSuccess(updatedTransaction));
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * Cancel Payment Link transacion before it's processed
 */
export function cancelPaymentLinkTransaction(transactionId, source) {
  return function (dispatch) {
    let processCancel;
    if (source === TransactionSource.PayFabric) {
      processCancel = cancelPayFabricTransaction;
    } else if (source === TransactionSource.Spreedly) {
      processCancel = spreedlyApi.cancelTransaction;
    }

    return processCancel(transactionId)
      .then(() => {
        dispatch({
          type: StoreActions.CANCEL_TRANSACTION,
        });
        return readTransaction(transactionId)(dispatch);
      })
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * Resend Spreedly text link
 * @param {object} args
 * args = {
 *  transactionId,
 *  phone,
 *  email
 * }
 */
export function resendPaymentLink(args, source) {
  return function (dispatch) {
    let processResend;
    if (source === TransactionSource.PayFabric) {
      processResend = resendPayFabricPaymentLink;
    } else if (source === TransactionSource.Spreedly) {
      processResend = spreedlyApi.resendLink;
    }

    return processResend(args)
      .then((transaction) => {
        dispatch({
          type: StoreActions.RESEND_SPREEDLY_LINK,
          transaction,
        });
        return transaction;
      })
      .catch((err) => {
        throw err;
      });
  };
}

/**
 * Create a transaction
 */
export function processNewTransaction(transaction) {
  return async function (dispatch) {
    const response = await createTransaction(transaction);
    dispatch({
      type: StoreActions.PROCESS_NEW_TRANSACTION_SUCCESS,
      transaction: response,
    });
    return response;
  };
}
