import {
  handleResponse,
  handleError,
  axiosPracticeInstance as axios,
} from './index';
import map from './map';

// get store object
//import { store } from './../store/storeConfig'

const practiceURL = '/practice';
const getURL = '/get';
const updateURL = '/update';

/**
 * Get Practices (List)
 */
export function getPractices() {
  return axios
    .post(`${getURL}`)
    .then(handleResponse)
    .then((res) => res.map(map.practice.toModel))
    .catch(handleError);
}

/**
 * Get Practice By ID
 * @param {*} id
 */
export function getPracticeByID(id) {
  if (
    typeof id !== 'string' ||
    !/[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}/.test(id)
  )
    throw new Error('Invalid ID');

  const body = { id };

  return axios
    .post(`${getURL}`, body)
    .then(handleResponse)
    .then(map.practice.toModel)
    .catch(handleError);
}

/**
 * Get Practice Detail
 * @param {*} id
 */
export function readPractice(id) {
  console.log(id);
  const body = id ? { id } : {};
  return axios
    .post(`${getURL}`, body)
    .then((res) => {
      console.log({ res });
      return res;
    })
    .then(handleResponse)
    .then(map.practice.toModel)
    .catch(handleError);
}

/**
 * Add New Practice
 * @param {*} practice
 */
export function addPractice(practice) {
  const { contactEmail, email, name, password, phone, stripeId } = practice;

  const body = {
    contactEmail,
    email,
    name,
    password,
    phone: (phone ?? '').toString(),
    stripeId,
  };

  return axios.post(updateURL, body).then(handleResponse).catch(handleError);
}

/**
 * Update Practice
 * @param {*} practice
 */
export function updatePractice(practice) {
  const payload = {
    ...practice,
    ...{
      fee_percentage: practice.fee_percentage
        ? parseFloat(practice.fee_percentage)
        : 0,
      installment_fee: practice.installment_fee
        ? parseFloat(practice.installment_fee)
        : 0,
      phone: practice.phone ? parseFloat(practice.phone) : null,
    },
  };
  return axios.post(updateURL, payload).then(handleResponse).catch(handleError);
}

/**
 * Manually confirm user
 * @param {*} practice
 */
export function verifyPractice(practice) {
  const body = {
    username: practice.user_sub,
    practice_id: practice.id,
  };

  return axios
    .post(`${practiceURL}/confirm`, body)
    .then(handleResponse)
    .catch(handleError);
}
