import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as transactionDetailActions from './../../../../utils/store/actions/transactionDetailActions';
import TransactionHeader from './TransactionHeader';
import Loading from './../../../Common/Loading';
import TransactionInfo from './TransactionInfo';
import TransactionInfoFooter from './TransactionInfoFooter';
import TransactionRefundConfirm from './TransactionRefundConfirm';
import TransactionVoidConfirm from './TransactionVoidConfirm';
import CancelLinkConfirm from 'components/Layout/Content/TransactionDetail/CancelLinkConfirm';
import ResendReceiptModal from './ResendReceiptModal';
import TransactionSource from 'constants/TransactionSource';
import styles from './styles/Transaction.module.scss';
import ErrorCard from '../../../Common/ErrorCard/ErrorCard';

class Transaction extends Component {
  state = {
    additionalEmail: '',
    isError: false,
    isLoading: false,
    isSaving: false,
    refundFormSubmitted: false,
    refundValue: '',
    resendingTransaction: false,
    showAddMailModal: false,
    showCancelLinkConfirm: false,
    showModal: false,
    showVoidModal: false,
    title: 'Invoice Detail',
    transaction: {},
  };

  fetchData = () => {
    const { readTransaction, match, selectedTransaction } = this.props;
    const transactionId = match.params.transactionId;
    readTransaction(transactionId)
      .then(() => {
        this.setState({
          isLoading: false,
          transaction: {
            ...selectedTransaction,
            ...{
              pet_parent_email: selectedTransaction.pet_parent_email
                ? selectedTransaction.pet_parent_email
                : '',
            },
          },
          refundValue: '',
        });
      })
      .catch(() => {
        this.setState({ isLoading: false, isError: true });
        toast.error('Error obtaining transaction');
      });
  };

  showModalConfirm = (event) => {
    // TODO: Text2Pay refunds
    event.preventDefault();
    const { transaction, refundValue } = this.state;
    if (
      (refundValue && refundValue > 0) ||
      transaction.isInstallments ||
      transaction.isPaymentLink
    ) {
      this.setState({ showModal: true });
    }
  };

  onCancelRefund = () => {
    this.setState({
      refundValue: '',
      transaction: {
        ...this.state.transaction,
      },
      showModal: false,
    });
  };

  onSendReceipt = () => {
    this.setState({
      showAddMailModal: true,
      isSaving: false,
    });
  };

  onRefundValueChange = (event) => {
    const { value } = event.target;
    const { selectedTransaction } = this.props;
    const refundableAmount =
      (selectedTransaction.amount -
        (selectedTransaction.amountRefunded
          ? selectedTransaction.amountRefunded
          : 0)) /
      100;
    if (value >= 0 && value <= refundableAmount) {
      this.setState({
        transaction: {
          ...this.state.transaction,
        },
        refundValue: value,
      });
    } else {
      if (value < 0) {
        toast.warn('Only positive numbers are allowed');
      }
      if (value > refundableAmount) {
        toast.warn(
          `Max amount allowed is ${Number.parseFloat(refundableAmount).toFixed(
            2
          )}`
        );
      }
    }
  };

  onRefund = () => {
    const { processTransactionRefund } = this.props;
    const { transaction, refundValue } = this.state;
    if (refundValue && refundValue > 0) {
      this.setState({ isSaving: true });
      processTransactionRefund(transaction, refundValue)
        .then(() => {
          this.setState({
            isSaving: false,
            showModal: false,
            refundValue: '',
            refundFormSubmitted: true,
          });
          toast.success('Refund has been processed successfully');
        })
        .catch((err) => {
          this.setState({
            isSaving: false,
            showModal: false,
          });
          let message =
            err && err.userMessage
              ? err.userMessage
              : 'Unable to process refund';
          toast.error(message);
        });
    } else if (transaction.isInstallments) {
      this.setState({ isSaving: true });
      processTransactionRefund(transaction)
        .then(() => {
          this.setState({
            isSaving: false,
            showModal: false,
          });
          toast.success('Refund has been processed successfully');
        })
        .catch(() => {
          this.setState({
            isSaving: false,
            showModal: false,
          });
          toast.error('Unable to process refund');
        });
    }
  };

  onCancelSend = () => {
    this.setState({
      showAddMailModal: false,
    });
  };

  onAdditionalEmailChange = (event) => {
    const { value } = event.target;
    this.setState({
      transaction: {
        ...this.state.transaction,
        ...{ pet_parent_email: value },
      },
    });
  };

  onTransactionAttributeChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      transaction: { ...this.state.transaction, ...{ [name]: value } },
    });
  };

  onCancelLink = (event) => {
    this.setState({
      showCancelLinkConfirm: true,
      isSaving: false,
    });
  };

  onCancelLinkCanceled = (event) => {
    this.setState({
      showCancelLinkConfirm: false,
    });
  };

  onCancelLinkConfirmed = (event) => {
    const { cancelPaymentLinkTransaction } = this.props;
    const {
      transaction: { transactionId, source },
    } = this.state;
    this.setState({ isSaving: true });

    cancelPaymentLinkTransaction(transactionId, source)
      .then(() => {
        this.setState({
          isSaving: false,
          showCancelLinkConfirm: false,
        });
        toast.success('The payment link has been canceled successfully');
      })
      .catch(() => {
        this.setState({
          isSaving: false,
          showCancelLinkConfirm: false,
        });
        toast.error('Unable to cancel the payment link');
      });
  };

  /**
   *
   * @param {object} args
   * args ={
   *  transactionId,
   *  phone,
   *  email
   * }
   * @param {function} callback
   */
  onResendPaymentLink = async (args, callback) => {
    this.setState({
      resendingTransaction: true,
    });
    const { source } = this.state.transaction;

    try {
      await this.props.resendPaymentLink(args, source);
      /**
       * This is technical debt in some sense
       * The resendPaymentLink call returns a new transaction
       * which could be used to update the Redux store / component state
       * But there isn't an easy way to do that currently
       * Particularly the messageHistory which is not specifically
       * returned in the api response
       * The fetchData function gets the transaction again but handles all the updating
       */
      this.fetchData();
    } catch (error) {
      toast.error('Cannot resend Spreedly transaction link');
    } finally {
      this.setState({
        resendingTransaction: false,
      });

      callback();
    }
  };

  showVoidModalConfirm = () => {
    this.setState({ showVoidModal: true });
  };

  onCancelVoid = () => {
    this.setState({ showVoidModal: false });
  };

  onVoid = () => {
    const { processTransactionVoid, refreshDailyTransaction } = this.props;
    const { transaction } = this.state;
    this.setState({ isSaving: true });
    processTransactionVoid(transaction)
      .then(() => {
        this.setState({
          isSaving: false,
          showVoidModal: false,
        });
        if (refreshDailyTransaction) refreshDailyTransaction();
        toast.success('Void has been processed successfully');
      })
      .catch((err) => {
        this.setState({
          isSaving: false,
          showVoidModal: false,
        });
        let message =
          err && err.userMessage ? err.userMessage : 'Unable to process refund';
        toast.error(message);
      });
  };

  componentDidMount() {
    const { match } = this.props;
    this.setState({ isLoading: true });
    if (match.params.transactionId) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedTransaction } = this.props;
    if (
      (!prevProps.selectedTransaction.transactionId &&
        selectedTransaction.transactionId) ||
      prevProps.selectedTransaction.transactionId !==
        selectedTransaction.transactionId
    ) {
      this.fetchData();
    }
  }

  render() {
    const {
      isError,
      isLoading,
      isSaving,
      refundFormSubmitted,
      refundValue,
      resendingTransaction,
      showAddMailModal,
      showCancelLinkConfirm,
      showModal,
      showVoidModal,
      title,
      transaction,
    } = this.state;

    const { selectedTransaction } = this.props;

    if (isError && !isLoading) {
      return (
        <div id='Transaction' className='transaction content'>
          <TransactionHeader title={title} />
          {ErrorCard('We ran into an issue! Please try again later!')}
        </div>
      );
    }

    return (
      <div id='Transaction' className='transaction content'>
        <TransactionHeader title={title} />
        {isLoading && <Loading />}
        {selectedTransaction &&
          !isLoading &&
          Object.keys(selectedTransaction).length > 0 && (
            <div className={`${styles.transactionCard}`}>
              <div className='vitus-vet-box'>
                <TransactionInfo
                  transaction={selectedTransaction}
                  onCancelLink={this.onCancelLink}
                  isSaving={isSaving}
                  onResendPaymentLink={this.onResendPaymentLink}
                  resendingTransaction={resendingTransaction}
                />
                <TransactionInfoFooter
                  refundValue={refundValue}
                  onRefundValueChange={this.onRefundValueChange}
                  onRefund={this.showModalConfirm}
                  onVoid={this.showVoidModalConfirm}
                  onSendReceipt={this.onSendReceipt}
                  transaction={transaction}
                  isSaving={isSaving}
                  selectedTransaction={selectedTransaction}
                  refundFormSubmitted={refundFormSubmitted}
                />
              </div>
            </div>
          )}
        <TransactionRefundConfirm
          onCancel={this.onCancelRefund}
          onConfirm={this.onRefund}
          transaction={transaction}
          refundValue={refundValue}
          show={showModal}
          isSaving={isSaving}
          onRefundReasonChange={this.onTransactionAttributeChange}
        />
        {(selectedTransaction.authNetStatus === 'capturedPendingSettlement' ||
          selectedTransaction.source ===
            TransactionSource.PayFabric) /* we can attempt payfabric voids */ && (
          <TransactionVoidConfirm
            onCancel={this.onCancelVoid}
            onConfirm={this.onVoid}
            transaction={transaction}
            voidValue={selectedTransaction.amount}
            show={showVoidModal}
            isSaving={isSaving}
            onVoidReasonChange={this.onTransactionAttributeChange}
          />
        )}
        {this.state.transaction.transactionId && showAddMailModal ? (
          <ResendReceiptModal
            show={showAddMailModal}
            savedPhone={this.state.transaction?.cleanPet_parent_phone ?? ''}
            savedEmail={this.state.transaction?.cleanPet_parent_email ?? ''}
            toggleModal={() => {
              this.setState({ showAddMailModal: !this.state.showAddMailModal });
            }}
            transactionId={this.state.transaction?.transactionId}
            isInstallments={this.state.transaction?.isInstallments}
          />
        ) : null}
        <CancelLinkConfirm
          onCancel={this.onCancelLinkCanceled}
          onConfirm={this.onCancelLinkConfirmed}
          transaction={transaction}
          show={showCancelLinkConfirm}
          isSaving={isSaving}
        />
      </div>
    );
  }
}

Transaction.propTypes = {
  selectedTransaction: PropTypes.object.isRequired,
  readTransaction: PropTypes.func.isRequired,
  processTransactionRefund: PropTypes.func.isRequired,
  loggedPractice: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  selectedTransaction: state.selectedTransaction,
  loggedPractice: state.loggedPractice,
});

const mapDispatchToProps = {
  ...transactionDetailActions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Transaction));
