// Modules
import React from 'react';

// Utils
import generateRandomID from 'utils/helpers/generateRandomID';
import phoneNumberIsValidFormat from 'utils/helpers/phoneNumberValidate';
import { emailValidate } from 'utils/helpers/emailValidate';
import { cloneDeep } from 'lodash';
import { getformDataWithBooleans } from 'utils/helpers/formDataHelpers';

// Styles
/**
 * Because we are only use this for our grid-area classes
 * as defined in sharedStyles.scss, we could technically
 * just use those classes names without this import.
 *
 * However, by doing it this way we are useing scoped names
 * that prevent any possible naming collisions
 */
import styles from './PracticeEditView.module.scss';

// Components
import { InputGroup, Form, Button } from 'react-bootstrap';
import WorkingAreaTooltip from 'components/Common/WorkingAreaTooltip/WorkingAreaTooltip';
import SelectField from 'components/Common/SelectField/SelectField';
import IdShortener from 'utils/helpers/IdShortener';
import { DeviceTypes } from 'constants/Payfabric';

function getHeader(header, inDevelopment = false) {
  return (
    <Form.Text className='mb-2 vitus-primary-orange text-uppercase'>
      {header}
      {inDevelopment ? (
        <span className='ml-1 vitus-primary-blue' style={{ fontSize: '11px' }}>
          <WorkingAreaTooltip />
        </span>
      ) : null}
    </Form.Text>
  );
}

function getChoiceLabel(choice) {
  if (choice?.constructor?.name === 'Object' && 'label' in choice) {
    return choice.label;
  } else if (typeof choice === 'string' || typeof choice === 'number') {
    return choice;
  } else {
    return '';
  }
}

function getChoiceValue(choice, registeredValues = {}) {
  if (choice?.constructor?.name === 'Object' && 'value' in choice) {
    return choice.value;
  } else if (typeof choice === 'string') {
    const val = registeredValues[choice.toLowerCase()];
    return val ? val : choice;
  } else if (choice) {
    return choice;
  } else {
    return '';
  }
}

function getCheckboxName(choice, name) {
  if (choice?.constructor?.name === 'Object' && 'value' in choice) {
    return `${name}-check-${choice.value}`;
  } else if (typeof choice === 'string') {
    return `${name}-check-${choice}`;
  } else {
    return name;
  }
}

function getLabelFlexBasis(choices) {
  const lengths = [];

  for (let choice of choices) {
    if (choice?.constructor?.name === 'Object' && 'label' in choice) {
      lengths.push(choice.label.length);
    } else if (typeof choice === 'string') {
      lengths.push(choice.length);
    }
  }

  return `${Math.max(...lengths)}ch`;
}

/**
 * Remember
 *      choice comes from an array within registered fields
 *      val is the value stored in the database
 */
function isDefaultChecked(choice, val) {
  const { value } = choice;

  if ((val === null || val === undefined) && typeof value === 'boolean') {
    // If no value in database, choice of false is checked by default
    return !value;
  } else if (typeof value === 'boolean' && typeof val === 'boolean') {
    return val === value;
  } else if (typeof val === 'string') {
    return val.includes(value.toString());
  } else {
    return false;
  }
}

function getRadioButtons(fieldData) {
  const { name, val, header, choices, inDevelopment, isDisabled } = fieldData;

  const buttonValues = {
    yes: true,
    no: false,
  };

  const flexBasis = getLabelFlexBasis(choices);

  return (
    <Form.Group key={generateRandomID()} className={styles[name]}>
      {getHeader(header, inDevelopment)}

      {choices.map((c) => (
        <Form.Label
          className='flex-row d-flex align-items-center'
          key={generateRandomID()}
        >
          <span style={{ flexBasis }}>{getChoiceLabel(c)}</span>

          <input
            key={c}
            type='radio'
            name={name}
            value={getChoiceValue(c, buttonValues)}
            className='ml-2'
            style={{ width: 'auto' }}
            defaultChecked={isDefaultChecked(c, val)}
            disabled={isDisabled}
          />
        </Form.Label>
      ))}
    </Form.Group>
  );
}

function getNotEditable(fieldData) {
  const { name, val, header, inDevelopment } = fieldData;

  return (
    <Form.Group key={generateRandomID()} className={styles[name]}>
      {getHeader(header, inDevelopment)}

      <p key={name}>{val ? val : 'unassigned'}</p>
    </Form.Group>
  );
}

function getTextInputWithIcon(fieldData) {
  const {
    name,
    val,
    header,
    placeholder = 'Enter value',
    label,
    icon,
    inDevelopment,
    prependIcon = false,
    isDisabled,
  } = fieldData;

  return (
    <Form.Group key={generateRandomID()} className={styles[name]}>
      {getHeader(header, inDevelopment)}

      <InputGroup className='mb-3' key={generateRandomID()}>
        {prependIcon ? (
          <InputGroup.Prepend>
            <InputGroup.Text>
              <i className={`${icon}`}></i>
            </InputGroup.Text>
          </InputGroup.Prepend>
        ) : null}

        <Form.Control
          type='text'
          name={name}
          placeholder={placeholder}
          defaultValue={val ?? ''}
          aria-label={label ?? header}
          disabled={isDisabled}
        />

        {!prependIcon ? (
          <InputGroup.Append>
            <InputGroup.Text>
              <i className={`${icon}`}></i>
            </InputGroup.Text>
          </InputGroup.Append>
        ) : null}
      </InputGroup>
    </Form.Group>
  );
}

function getTextInput(fieldData) {
  const {
    name,
    val,
    header,
    placeholder = 'Enter value',
    label,
    inDevelopment,
    isDisabled,
  } = fieldData;

  return (
    <Form.Group key={generateRandomID()} className={styles[name]}>
      {getHeader(header, inDevelopment)}

      <Form.Control
        type='text'
        name={name}
        placeholder={placeholder}
        defaultValue={val ?? ''}
        aria-label={label ?? header}
        disabled={isDisabled}
      />
    </Form.Group>
  );
}

function getEmailInput(fieldData) {
  const {
    name,
    val,
    header,
    placeholder = 'Enter value',
    label,
    inDevelopment,
    isDisabled,
  } = fieldData;

  return (
    <Form.Group key={generateRandomID()} className={styles[name]}>
      {getHeader(header, inDevelopment)}

      <Form.Control
        type='text'
        name={name}
        placeholder={placeholder}
        defaultValue={val ?? ''}
        aria-label={label ?? header}
        disabled={isDisabled}
      />
    </Form.Group>
  );
}

function getCheckboxes(fieldData) {
  const { name, val, header, choices, inDevelopment, isDisabled } = fieldData;

  const flexBasis = getLabelFlexBasis(choices);

  return (
    <Form.Group key={generateRandomID()} className={styles[name]}>
      {getHeader(header, inDevelopment)}

      {choices.map((c) => (
        <Form.Label
          className='flex-row d-flex align-items-center'
          key={generateRandomID()}
        >
          <span style={{ flexBasis }}>{getChoiceLabel(c)}</span>

          <input
            key={c}
            type='checkbox'
            name={getCheckboxName(c, name)}
            className='ml-2'
            style={{ width: 'auto' }}
            defaultChecked={isDefaultChecked(c, val)}
            disabled={isDisabled}
          />
        </Form.Label>
      ))}
    </Form.Group>
  );
}

function getButton(fieldData) {
  const {
    name,
    header,
    handleClick = () => null,
    isDisabled = false,
  } = fieldData;

  return (
    <Form.Group key={generateRandomID()} className={styles[name]}>
      {getHeader(header)}

      <Button
        disabled={isDisabled}
        variant='outline-secondary'
        onClick={handleClick}
      >
        EDIT
      </Button>
    </Form.Group>
  );
}

// All fieldData properties:
//   {
//       mapToJSX       <boolean>,
//       name           <string>,
//       val            <string | boolean>,
//       header         <string>,
//       editable       <boolean>,
//       type           <string>,
//       choices        <array>,
//       placeholder    <string>,
//       label          <string>,
//       icon           <string>,
//       inDevelopment  <boolean>,
//       prependIcon    <boolean>,
//   }

export function getRegisteredFieldsJSX(fieldData) {
  const { mapToJSX, editable, type, icon, inputEl } = fieldData;

  switch (true) {
    case !mapToJSX:
      return null;

    case !editable:
      return getNotEditable(fieldData);

    case type === 'text' && typeof icon === 'string':
      return getTextInputWithIcon(fieldData);

    case type === 'text':
      return getTextInput(fieldData);

    case type === 'email':
      return getEmailInput(fieldData);

    case type === 'checkbox':
      return getCheckboxes(fieldData);

    case type === 'radio':
      return getRadioButtons(fieldData);

    case inputEl === 'button':
      return getButton(fieldData);

    default:
      return null;
  }
}

function getBasicInput(key, value, labels) {
  return (
    <input
      key={`${key}_${value}`}
      className='form-control'
      type='text'
      name={key}
      defaultValue={value ?? ''}
      placeholder='Enter value'
      aria-label={labels[key]}
    />
  );
}

function getBasicSelect(key, value) {
  return (
    <SelectField
      key={generateRandomID()}
      label='Status'
      options={[
        { value: true, label: 'Active' },
        { value: false, label: 'Disabled' },
      ]}
      name={key}
      defaultValue={key === value.toLowerCase()}
    />
  );
}

export function getBasicDetailsJSX(details) {
  const [key, value] = details;

  const labels = {
    coreId: 'vitusvet id',
    phone: 'practice phone',
    email: 'practice email',
  };

  switch (key) {
    case 'id':
      return (
        <IdShortener
          key={value}
          id={value}
          styles={{
            fontSize: '14px',
            fontFamily: "'Montserrat-Medium', sans-serif",
          }}
        />
      );

    case 'email':
      return <p key={`${key}_${value}`}>{value}</p>;

    case 'coreId':
      return getBasicInput(key, value, labels);

    case 'name':
      return getBasicInput(key, value, labels);

    case 'phone':
      return getBasicInput(key, value, labels);

    case 'active':
      return getBasicSelect(key, value);

    default:
      return null;
  }
}

const validationRegex = {
  twoDecimalFloat: /^\d+(?:\.\d\d?)?$/,
  integer: /^\d+$/,
};

const validationFunctions = {
  name(value) {
    return value && typeof value === 'string' && value.length > 0;
  },

  phone(value) {
    return phoneNumberIsValidFormat(value);
  },

  contactEmail(value) {
    if (!value) return true;
    return emailValidate(value);
  },

  merchantFeePercentage(value) {
    return validationRegex.twoDecimalFloat.test(value);
  },

  installmentFeePercentage(value) {
    return validationRegex.twoDecimalFloat.test(value);
  },

  paymentLinkExpiration(value) {
    // (ExpirationHours) should be integer and must be greater than 0
    return validationRegex.integer.test(value) && +value > 0 && +value < 1000;
  },

  installmentsMinAmount(value) {
    return validationRegex.twoDecimalFloat.test(value);
  },
};

export function validateFormData(data) {
  const fields = {};

  for (let key in data) {
    const fn = validationFunctions[key];

    if (typeof fn !== 'function') continue;

    fields[key] = fn(data[key]);
  }

  return fields;
}

export function showValidationStyles(form, validationObj, styleObj) {
  for (let key in validationObj) {
    const el = form.elements[key];

    if (!validationObj[key]) {
      el.classList.add('is-invalid');
      el.classList.add(styleObj.isInvalid);
      el.focus();
    } else {
      el.classList.remove('is-invalid');
      el.classList.remove(styleObj.isInvalid);
    }
  }
}

function getFormDataWithInstallmentsPlan(formData) {
  const copy = cloneDeep(formData);

  const installmentsPlan = [];

  for (let key in copy) {
    if (/installmentsPlan-check-/.test(key)) {
      installmentsPlan.push(key.replace('installmentsPlan-check-', ''));
      delete copy[key];
    }
  }

  copy.installmentsPlan = installmentsPlan.join(',');

  return copy;
}

function getFormDataWithRequiredMobileFields(formData) {
  const copy = cloneDeep(formData);

  // store mobileRequiredFields as object (dynamo map)
  copy.mobileRequiredFields = {
    requireStaffMember: copy.requireStaffMember,
    requirePetOwnerName: copy.requirePetOwnerName,
    requireDescription: copy.requireDescription,
    requireRefundReason: copy.requireRefundReason,
  };

  // delete unmapped properties
  delete copy.requireStaffMember;
  delete copy.requirePetOwnerName;
  delete copy.requireDescription;
  delete copy.requireRefundReason;

  return copy;
}

export function getUpdatedFormData(formData, payProcessorData) {
  const formDataWithBooleans = getformDataWithBooleans(formData);

  const formDatatWithInstallmentsPlan =
    getFormDataWithInstallmentsPlan(formDataWithBooleans);

  if (payProcessorData.stripeId)
    formDatatWithInstallmentsPlan.stripeId = payProcessorData.stripeId;

  if (payProcessorData.authNetLogin && payProcessorData.authNetPassword) {
    const spreedlyGateway = {
      authNet: {
        login: payProcessorData.authNetLogin,
        password: payProcessorData.authNetPassword,
      },
    };

    formDatatWithInstallmentsPlan.spreedlyGateway = spreedlyGateway;
  }

  // set payFabric fields
  if (!!payProcessorData.payFabricDevices) {
    formDatatWithInstallmentsPlan.payFabricDeviceIds = {};
    formDatatWithInstallmentsPlan.payFabricSetupIds = {};
    payProcessorData.payFabricDevices.forEach((val) => {
      const { id, setupId, password } = DeviceTypes[val.deviceType];
      formDatatWithInstallmentsPlan.payFabricDeviceIds[id] = val[id];
      formDatatWithInstallmentsPlan.payFabricSetupIds[setupId] = val[setupId];
      formDatatWithInstallmentsPlan[password] = val[password];
    });
  }

  return getFormDataWithRequiredMobileFields(formDatatWithInstallmentsPlan);
}
