const Env = {
  endpoints: {
    auth: process.env.REACT_APP_API_ENDPOINT_AUTH,
    user: process.env.REACT_APP_API_ENDPOINT_USER,
    practice: process.env.REACT_APP_API_ENDPOINT_PRACTICE,
    transaction: process.env.REACT_APP_API_ENDPOINT_TRANSACTION,
    merchant: process.env.REACT_APP_API_ENDPOINT_MERCHANT,
    spreedly: process.env.REACT_APP_API_ENDPOINT_SPREEDLY,
    installments: process.env.REACT_APP_API_ENDPOINT_INSTALLMENTS,
    websocket: process.env.REACT_APP_API_ENDPOINT_WEBSOCKET,
    customers: process.env.REACT_APP_API_ENDPOINT_CUSTOMERS,
    payfabric: process.env.REACT_APP_API_ENDPOINT_PAYFABRIC,
  },
  environment: process.env.REACT_APP_ENVIRONMENT_LABEL,
  stripeClientId: process.env.REACT_APP_STRIPE_CLIENT_ID,
  logRocketEnabled: process.env.REACT_APP_LOGROCKET_ENABLED === 'true',
  logRocketAppId: process.env.REACT_APP_LOGGING_APP_ID,
  sentryEnabled: process.env.REACT_APP_SENTRY_ENABLED === 'true',
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  sanityChecksEnabled: process.env.REACT_APP_SANITY_CHECKS_ENABLED === 'true',
  googleAnalyticsEnabled:
    process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === 'true',
  googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  spreedlyEnvironmentKey: process.env.REACT_APP_SPREEDLY_ENVIRONMENT_KEY,
  appCuesTrackingEnabled:
    process.env.REACT_APP_APPCUES_TRACKING_ENABLED === 'true',
  mixpanelEnabled: process.env.REACT_APP_MIXPANEL_ENABLED === 'true',
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
  coreUrl: process.env.REACT_APP_CORE_URL,
};

export default Env;
