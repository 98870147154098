// Utils
import { cloneDeep } from 'lodash';

// Constants
import PaymentPlatforms from 'constants/PaymentPlatforms';

/**
 * registeredFields is used in PracticeDataView and PracticeEditView
 * to render JSX elements.
 *
 * You can opt out of the default render mechanism, but still pass data
 * down to these two components using this key / value pair:
 *      mapToJSX: false
 */
export const paymentOptionsRegisteredFields = {
  contactEmail: {
    mapToJSX: true,
    name: 'contactEmail',
    header: 'receipt email',
    editable: true,
    inputEl: 'input',
    type: 'email',
    placeholder: 'e.g.: mail@mydomain.com',
  },
  stripeId: {
    mapToJSX: false,
    name: 'stripeId',
    header: 'stripe id',
    editable: false,
  },
  merchantFeePercentage: {
    mapToJSX: true,
    name: 'merchantFeePercentage',
    header: 'merchant commission rate (full payment)',
    editable: true,
    inputEl: 'input',
    type: 'text',
    placeholder: 'Enter value',
    icon: 'fas fa-percent',
    prependIcon: false,
  },
  authNetLogin: {
    mapToJSX: false,
    name: 'authNetLogin',
    header: 'auth.net id',
    editable: false,
    inputEl: 'input',
    type: 'text',
  },
  authNetPassword: {
    mapToJSX: false,
    name: 'authNetPassword',
    header: 'spreedly token',
    editable: false,
    inputEl: 'input',
    type: 'text',
  },
  payFabricSetupId: {
    mapToJSX: false,
    name: 'payFabricSetupId',
    header: 'payfabric setup id',
    editable: false,
    inputEl: 'input',
    type: 'text',
  },
  payFabricDeviceId: {
    mapToJSX: false,
    name: 'payFabricDeviceId',
    header: 'payfabric device id',
    editable: false,
    inputEl: 'input',
    type: 'text',
  },
  paymentLinkEnabled: {
    mapToJSX: false,
    name: 'paymentLinkEnabled',
    header: 'allow payment request',
    editable: true,
    inputEl: 'input',
    type: 'radio',
    choices: [
      { label: 'YES', value: true },
      { label: 'NO', value: false },
    ],
  },
  onlyVetshipRxPayments: {
    mapToJSX: false,
    name: 'onlyVetshipRxPayments',
    header: 'only vetshipRx payments',
    editable: true,
    inputEl: 'input',
    type: 'radio',
    choices: [
      { label: 'YES', value: true },
      { label: 'NO', value: false },
    ],
  },
  paymentPlatform: {
    mapToJSX: true,
    name: 'paymentPlatform',
    header: 'payment platform',
    editable: true,
    inputEl: 'input',
    type: 'radio',
    choices: [
      { label: 'STRIPE', value: PaymentPlatforms.stripe },
      { label: 'SPREEDLY', value: PaymentPlatforms.spreedly },
      { label: 'PAYFABRIC', value: PaymentPlatforms.payfabric },
    ],
  },
  vitusPayExclusive: {
    mapToJSX: true,
    name: 'vitusPayExclusive',
    header: 'vituspay exclusive practice',
    editable: true,
    inputEl: 'input',
    type: 'radio',
    choices: [
      { label: 'YES', value: true },
      { label: 'NO', value: false },
    ],
  },
  paymentLinkCustomMessage: {
    mapToJSX: false,
    name: 'paymentLinkCustomMessage',
    header: 'payment request custom message',
    editable: true,
    inputEl: 'button',
    // When inputEl === 'button' there should also be a handleClick property
    // For this particular button the handleClick is defined later
  },
  paymentLinkExpiration: {
    mapToJSX: true,
    name: 'paymentLinkExpiration',
    header: 'payment request expiration (in hours)',
    editable: true,
    inputEl: 'input',
    type: 'text',
    placeholder: 'Enter value',
  },
  payWithSavedCards: {
    mapToJSX: true,
    name: 'payWithSavedCards',
    header: 'Pay With Saved Cards',
    editable: true,
    inputEl: 'input',
    type: 'radio',
    choices: [
      { label: 'YES', value: true },
      { label: 'NO', value: false },
    ],
  },
  paymentLinkEmailUpdateEnabled: {
    mapToJSX: true,
    name: 'paymentLinkEmailUpdateEnabled',
    header: 'payment request email updates',
    editable: true,
    inputEl: 'input',
    type: 'radio',
    choices: [
      { label: 'YES', value: true },
      { label: 'NO', value: false },
    ],
  },
  invoiceWritebackEnabled: {
    mapToJSX: true,
    name: 'invoiceWritebackEnabled',
    header: 'Invoice WriteBack (Avimark, Cornerstone, Impromed Only)',
    editable: true,
    inputEl: 'input',
    type: 'radio',
    choices: [
      { label: 'YES', value: true },
      { label: 'NO', value: false },
    ],
  },
  bitwerxPracticeId: {
    mapToJSX: true,
    name: 'bitwerxPracticeId',
    header: 'Bitwerx Practice Id',
    editable: true,
    inputEl: 'input',
    type: 'text',
  },
};

export const paxDeviceRegisteredFields = {
  requireStaffMember: {
    mapToJSX: true,
    name: 'requireStaffMember',
    header: 'staff member required',
    editable: true,
    inputEl: 'input',
    type: 'radio',
    choices: [
      { label: 'YES', value: true },
      { label: 'NO', value: false },
    ],
  },
  requirePetOwnerName: {
    mapToJSX: true,
    name: 'requirePetOwnerName',
    header: 'pet owner name required',
    editable: true,
    inputEl: 'input',
    type: 'radio',
    choices: [
      { label: 'YES', value: true },
      { label: 'NO', value: false },
    ],
  },
  requireDescription: {
    mapToJSX: true,
    name: 'requireDescription',
    header: 'description required',
    editable: true,
    inputEl: 'input',
    type: 'radio',
    choices: [
      { label: 'YES', value: true },
      { label: 'NO', value: false },
    ],
  },
  requireRefundReason: {
    mapToJSX: true,
    name: 'requireRefundReason',
    header: 'refund reason required',
    editable: true,
    inputEl: 'input',
    type: 'radio',
    choices: [
      { label: 'YES', value: true },
      { label: 'NO', value: false },
    ],
  },
  mobileSignatureCaptureMinimumAmount: {
    mapToJSX: true,
    name: 'mobileSignatureCaptureMinimumAmount',
    header: 'signature capture minimum amount',
    editable: true,
    inputEl: 'input',
    type: 'text',
    icon: 'fas fa-dollar-sign',
    prependIcon: true,
  },
};

export function updateRegisteredFields(practice, fieldsObj) {
  const copy = cloneDeep(fieldsObj);

  for (let key in copy) {
    if (key === 'authNetLogin') {
      copy[key].val = practice?.spreedlyGateway?.authNet?.login;
    } else if (key === 'authNetPassword') {
      copy[key].val = practice?.spreedlyGateway?.authNet?.token;
    } else if (isMobileRequirementField(key)) {
      copy[key].val = practice?.mobileRequiredFields
        ? practice.mobileRequiredFields[key]
        : false;
    } else if (key === 'payFabricSetupId') {
      copy[key].val =
        practice?.payFabricSetupIds?.defaultSetupId ||
        practice?.payFabricSetupIds?.savedCardSetupId ||
        practice?.payFabricSetupIds?.terminalSetupId;
    } else if (key === 'payFabricDeviceId') {
      copy[key].val =
        practice?.payFabricDeviceIds?.defaultDeviceId ||
        practice?.payFabricDeviceIds?.savedCardDeviceId ||
        practice?.payFabricDeviceIds?.terminalDeviceId;
    } else {
      copy[key].val = practice[key];
    }
  }

  return copy;
}

export function getValuesForBasicDetails(practice, propNames) {
  const mainDetails = [];

  for (let name of propNames) {
    let value = practice[name];

    if (name === 'active') {
      value = value ? 'Active' : 'Disabled';
    }

    mainDetails.push([name, value]);
  }

  return mainDetails;
}

export function isMobileRequirementField(field) {
  const mobileRequiredFields = [
    'requireStaffMember',
    'requirePetOwnerName',
    'requireDescription',
    'requireRefundReason',
  ];

  return mobileRequiredFields.includes(field);
}
